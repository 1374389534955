const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const AUTH_REQUEST = "AUTH_REQUEST";
const REGISTER = "REGISTER";
const AUTH_ERROR = "AUTH_ERROR";
const FORGOT_PWD = "FORGOT_PWD";
const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
const CONTACT_ERROR = "CONTACT_ERROR";
const CONTACT_US_SUBMITTED = "CONTACT_US_SUBMITTED";
const CONTACT_US_RESET = "CONTACT_US_RESET";
const START_LOADING = "START_LOADING";
const START_LOADING_TWO = "START_LOADING_TWO";
const START_LOADING_THREE = "START_LOADING_THREE";
const SHOW_ERROR = "SHOW_ERROR";
const RESET_ALL = "RESET_ALL";
const ROOM_ERROR = "ROOM_ERROR";
const ADDING_NEW_ROOM = "ADDING_NEW_ROOM";
const ADD_NEW_ROOM_COMPLETED = "ADD_NEW_ROOM_COMPLETED";
const FETCHING_ALL_LISTING = "FETCHING_ALL_LISTING";
const FETCHED_ALL_LISTING = "FETCHED_ALL_LISTING";
const FETCHING_LISTING = "FETCHING_LISTING";
const FETCHED_LISTING = "FETCHED_LISTING";
const FETCHING_ROOM_LISTING = "FETCHING_ROOM_LISTING";
const FETCHED_ROOM_LISTING = "FETCHED_ROOM_LISTING";
const FETCHING_ROOMMATE_LISTING = "FETCHING_ROOMMATE_LISTING";
const FETCHED_ROOMMATE_LISTING = "FETCHED_ROOMMATE_LISTING";
const SEARCHING_ROOM = "SEARCHING_ROOM";
const LIST_CONTACT_SUCCESS = "LIST_CONTACT_SUCCESS";
const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
const SHOW_SUCCESS = "SHOW_SUCCESS";
const LIST_ITEMS_EMPTY = "LIST_ITEMS_EMPTY";
const FETCHED_STATISTICS = "FETCHED_STATISTICS";
const PROFILE_UPDATE = "PROFILE_UPDATE";
const PROFILE_UPDATE_PIC = "PROFILE_UPDATE_PIC";

export {
  LOGIN,
  LOGOUT,
  REGISTER,
  AUTH_REQUEST,
  AUTH_ERROR,
  FORGOT_PWD,
  CLEAR_AUTH_ERROR,
  CONTACT_ERROR,
  START_LOADING,
  START_LOADING_TWO,
  START_LOADING_THREE,
  SHOW_ERROR,
  RESET_ALL,
  CONTACT_US_RESET,
  CONTACT_US_SUBMITTED,
  ROOM_ERROR,
  ADDING_NEW_ROOM,
  ADD_NEW_ROOM_COMPLETED,
  FETCHING_LISTING,
  FETCHED_LISTING,
  FETCHING_ALL_LISTING,
  FETCHED_ALL_LISTING,
  FETCHING_ROOM_LISTING,
  FETCHED_ROOM_LISTING,
  FETCHING_ROOMMATE_LISTING,
  FETCHED_ROOMMATE_LISTING,
  SEARCHING_ROOM,
  UPDATE_PROFILE_SUCCESS,
  LIST_CONTACT_SUCCESS,
  SHOW_SUCCESS,
  LIST_ITEMS_EMPTY,
  FETCHED_STATISTICS,
  PROFILE_UPDATE,
  PROFILE_UPDATE_PIC,
};
