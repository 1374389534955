export const DATA = [
  {
    id: 1,
    name: "Eastern Lodge",
    noOfrooms: 2,
    price: 12000,
    image: "https://picsum.photos/200",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 2,
    name: "Westion Logde",
    noOfrooms: 1,
    price: 110000,
    image: "https://picsum.photos/300",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 3,
    name: "Smooth Even Hostel",
    noOfrooms: 1,
    price: 105000,
    image: "https://picsum.photos/500",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 4,
    name: "Bamidele Apartment",
    noOfrooms: 1,
    price: 190000,
    image: "https://picsum.photos/300",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 5,
    name: "Doubble flex logde",
    noOfrooms: 1,
    price: 160000,
    image: "https://picsum.photos/500",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 6,
    name: "Indigo suit Hotel new logde",
    noOfrooms: 1,
    price: 230000,
    image: "https://picsum.photos/400",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 7,
    name: "lamba hostel top",
    noOfrooms: 1,
    price: 300000,
    image: "https://picsum.photos/500",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
  {
    id: 8,
    name: "Two bedroom apartment",
    noOfrooms: 1,
    price: 220000,
    image: "https://picsum.photos/200",
    address: "Kampala Uganda",
    description:
      "Has all the neatness you will like and is first floor of the building",
    rating: 4.5,
    reviews: 10,
    extras: [
      "Plush pillows and breathable bed linens",
      "Soft, oversized bath towels",
      "Full-sized, pH-balanced toiletries",
      "Complimentary refreshments",
      "Adequate safety/security",
      "Internet",
      "Comfortable beds",
    ],
    owner: { id: 1, name: "Kirill", image: "https://picsum.photos/500" },
    booked: false,
    images: [
      "assets/img/eastern-lodge.jpg",
      "assets/img/eastern-lodge-2.jpg",
      "assets/img/eastern-lodge-3.jpg",
    ],
  },
];

export const WEAK_PASSWORD = [
  "12345",
  "abcde",
  "11111",
  "22222",
  "333333",
  "44444",
  "55555",
  "66666",
  "77777",
  "88888",
  "99999",
  "2468",
  "00000",
  "aaaaa",
  "zzzzz",
  "password",
  "....",
  ",,,,",
  ";;;;",
  ";;;;",
  "::::",
  "@@@@",
  "++++",
  "====",
];
