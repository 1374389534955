export const STATE = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

export const UNIVERSITIES = [
  "Abia State University",
  "Abubakar Tafawa Balewa University",
  "Achievers University",
  "Adamawa State University",
  "Adekunle Ajasin University",
  "Adeleke University",
  "Admiralty University",
  "Afe Babalola University",
  "African University of Science and Technology",
  "Ahmadu Bello University",
  "Ahman Pategi University",
  "Air Force Institute of Technology",
  "Ajayi Crowther University",
  "Akwa Ibom State University",
  "Al-Hikmah University",
  "Al-Istiqama University",
  "Al-Qalam University",
  "Ambrose Alli University",
  "American University of Nigerian",
  "ANAN Univerity",
  "Anchor University",
  "Arthur Javis University",
  "Atiba University",
  "Augustine University",
  "Ave-Maria University",
  "Babcock University",
  "Bamidele Olumiluwa University of Science & Technology",
  "Bauchi Stata University",
  "Bayelsa Medical University Yenagoa",
  "Bayero University",
  "Baze University",
  "Bells University of Technology",
  "Benson Idahosa University",
  "Benue State University",
  "Bingham University",
  "Borno State University",
  "Bowen University",
  "Caleb University",
  "Capital City University",
  "Caritas University",
  "Chrisland University",
  "Christopher University",
  "Chukwuemeka Odumegwu Ojukwu University",
  "Claretian University of Nigeria",
  "Clifford University",
  "Coal City University",
  "Confluence University of Scinece and Technology Osara",
  "Covenant University",
  "Crawford University",
  "Crescent University",
  "Cross River University of Technology",
  "Crown-Hill University",
  "Delta State University",
  "Dennis Osadebe University",
  "Dominican University",
  "Dominion University",
  "Eastern Palm University",
  "Ebonyi State University",
  "Edo University",
  "Edusoko University",
  "Edwin Clark University",
  "Ekiti State University (formerly University of Ado-Ekiti)",
  "Elizade University",
  "Enugu State Univesity of Science & Technology",
  "Evangel University",
  "Fed. Univ. of Petroleum Resources",
  "Federal University of Lafia",
  "Federal University of Lokoja",
  "Federal University of Agriculture, Abeokuta",
  "Federal University of Agriculture, Zuru",
  "Federal University of Health Sciences, Otukpo",
  "Federal University of Technology, Owerri",
  "Federal University of Technology, Akure",
  "Federal University of Technology, Minna",
  "Federal University Wukari",
  "Federal University, Birnin-Kebbi",
  "Federal University, Dutse",
  "Federal University, Dustin-Ma",
  "Federal University, Gashua",
  "Federal University, Gusau",
  "Federal University, Kashere",
  "Federal University, Ndufu-Alike",
  "Federal University, Otuoke",
  "Federal University, Oye-Ekiti",
  "Fountain University",
  "Godfrey Okoye University",
  "Gombe State University",
  "Gombe State University of Science and Technology",
  "Greenfield University",
  "Gregory University",
  "Hallmark University",
  "Havilla University",
  "Hezekiah University",
  "Ibrahim Babangida University",
  "Igbinedion University",
  "Ignatius Ajuru University of Education",
  "Imo State University",
  "James Hope University",
  "Joseph Ayo Babalola University",
  "Kaduna State University",
  "Kano University of Science and Technology",
  "Karl-Kumm University",
  "Kebbi State University of Science and Technology",
  "Khadija Univesity",
  "Kings University",
  "Kogi State University",
  "Kola Daisi University",
  "Kwara State University",
  "Kwararafa University",
  "Ladoke Akintola University of Technology",
  "Lagos State University",
  "Landmark University",
  "Lead City University",
  "Legacy University",
  "Madonna University",
  "Maitama Sule University",
  "Maranatha University",
  "Maryam Abacha American University",
  "Mcpherson University",
  "Mewar University",
  "Michael & Cecilia Ibru University",
  "Michael Okpara University of Agriculture",
  "Modibbo Adama University of Technology",
  "Moshood Abiola University of Science and Technology",
  "Mountain Top University",
  "Mudiame University",
  "Nasarawa State University",
  "National Open University Of Nigeria",
  "Niger-Delta University",
  "Nigeria Maritime University",
  "Nigerian Army University, Biu",
  "Nigerian Defence Academy",
  "Nile University",
  "Nnamdi Azikiwe University",
  "Nok University of Nigeria",
  "Novena University",
  "Obafemi Awolowo University",
  "Obong University",
  "Oduduwa University",
  "Olabisi Onabanjo University",
  "Olusegun Agagu University of Science and Technology",
  "Ondo State University of Medical Sciences",
  "Osun State University",
  "PAMO University of Medical Sciences",
  "Pan-Atlantic University",
  "Paul University",
  "Philomath University",
  "Plateau State University",
  "Precious Conerstone",
  "Redeemer's University",
  "Renaissance University",
  "Rhema University",
  "Ritman University",
  "Rivers State University",
  "Salem University",
  "Samuel Adegboyega University",
  "Skyline University, Nigeria",
  "Sokoto State University",
  "Southwestern Univerity",
  "Spiritan University",
  "Sule Lamido University",
  "Summit University",
  "Tai Solarin University Of Education",
  "Tansian University",
  "Taraba State University",
  "The Nigeria Police Academy, Wudil",
  "The Technical University",
  "Thomas Adewumi University",
  "Topfaith University",
  "Trinity University",
  "Umaru Musa Yar'Adua University",
  "University of Abuja",
  "University of Africa",
  "University of Agiculture, Makurdi",
  "University of Benin",
  "University of Calabar",
  "University of Delta",
  "University of Ibadan",
  "University of Ilorin",
  "University of Jos",
  "University of Lagos",
  "University of Maiduguri",
  "University of Mkar",
  "University of Nigeria",
  "University of Offa",
  "University of Port-Harcourt",
  "University of Science and Technology, Ozoro",
  "University of Uyo",
  "Usmanu Danfodiyo University",
  "Veritas University",
  "Wellspring University",
  "Wesley University, Ondo",
  "Western Delta University",
  "Westland University, Iwo",
  "Yobe State University",
  "Zamfara State University",
];

export const PRICE = [
  20000, 50000, 100000, 150000, 200000, 250000, 300000, 400000, 500000, 600000,
  800000, 1000000,
];
